import Shortheader from "../../components/shortheader/Shortheader";
import Form from "../homepage/components/contact/components/form/form";

export default function Contact() {
  return (
    <>
      <Shortheader />
      <main>
        <Form />
      </main>
    </>
  );
}
