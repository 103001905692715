import { useEffect, useState } from "react";
import style from "./about.module.scss";
import Mockup from "./components/mockup";
import { Link } from "react-router-dom";

export default function About() {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setPageWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <section id="A PROPOS" className={`${style.section}`}>
      <div className="section d-flex f-column jcc aic">
        <div className={pageWidth >= 1024 ? style.title : style.titleMobile}>
          <h2>A PROPOS</h2>
          <h3>concepteur et développeur d'application</h3>
        </div>
        <div
          className={`${
            pageWidth >= 1024 ? style.about : style.aboutMobile
          } d-flex jcsb`}
        >
          <div className={`${style.text} d-flex f-column`}>
            <h4>Plus qu'un metier, une passion !</h4>
            <p>
              Cette histoire commence en avril 2022, après une reconversion dans
              le numérique et plus précisement le développement web. C'est alors
              que la passion pour le code commence.
            </p>
            <h4>Les preuves de la réussite</h4>
            <p>
              Pendant cette conversion professionnelle, j'ai obtenu les titres
              de Développeur Web et Web Mobile, et Concepteur Développeur
              d'Application au centre de{" "}
              <Link to={"https://adapeco.com/"} target="_blank">
                <b>ADAPECO</b>
              </Link>{" "}
              à Béthune et maintenant je me lance en freelance concepteur
              développeur web full-stack.
            </p>
          </div>
          <div className={`${style.divider} d-flex jcc`}>
            <Mockup />
          </div>
          <div className={`${style.text} d-flex f-column`}>
            <h4>Projets adaptables sur tous les supports !</h4>
            <p>
              Tous les projets que je crée sont adaptables sur tous types de
              supports, que ce soit sur un smartphone ou un PC.
            </p>
            <h4>Des projets qui répondent à votre budget !</h4>
            <p>
              Je suis conscient que le coût d'un site internet ou d'une
              application peut être un frein sur l'achat de ces services. C'est
              pourquoi je vous propose des solutions adaptées à votre budget.
              Dans la mesure du possible, je peux également vous proposer des
              solutions plus abordables.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
