import { Link } from "react-router-dom";
import style from "./cookieConsent.module.scss";
import Popup from "../components/popup/popup";
import { useCookies } from "react-cookie";
import { useState } from "react";

export default function CookieConsent() {
  const [cookies, setCookie] = useCookies(["cookieConsent", false]);
  const [legales, setLegales] = useState(false);

  function giveCookieConsent() {
    setCookie("cookieConsent", true, { path: "/" }, { maxAge: 31536000 });
  }

  function handleLegales() {
    setLegales(!legales);
  }

  return (
    <div className={`${style.cookieConsent} d-flex jcsb`}>
      {legales && <Popup close={handleLegales} />}
      <p>
        Nous utilisons des cookies pour améliorer les expérience utilisateur.
        Pour utiliser ce site internet, vous accepter l'utilisation de ces
        cookies. <Link onClick={handleLegales}>En savoir plus.</Link>
      </p>
      <button onClick={giveCookieConsent}>Accepter</button>
    </div>
  );
}
