import style from "./popup.module.scss";
import { Link } from "react-router-dom";

export default function Popup({ close }) {
  const url = "https://florentin-crespel.fr";
  return (
    <div className={style.popup}>
      <div>
        <h2>Mentions légales</h2>
      </div>
      <div className={style.container}>
        <h4>1. Présentation du site.</h4>

        <p>
          En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la
          confiance dans l'économie numérique, il est précisé aux utilisateurs
          du site{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          l'identité des différents intervenants dans le cadre de sa réalisation
          et de son suivi :
        </p>
        <p>
          <b>Propriétaire :</b>
          <b> Florentin CRESPEL</b> – 313, rue nationale, résidence Matisse,
          appartement 10, 62290 Noeux-les-mines
        </p>
        <p>
          <b>Créateur :</b>
          <b> Florentin CRESPEL</b> -{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
        </p>
        <p>
          <b>Responsable publication :</b>
          <b> Florentin CRESPEL</b>
        </p>
        <p>
          <b>Webmaster :</b>
          <b> Florentin CRESPEL</b> – contact@florentin.crespel.fr
        </p>
        <p>
          <b>Hébergeur : </b>
          Vercel - 440 N Barranca Ave #4133, Covina, CA 91723
        </p>
        <br />
        <h4>
          2. Conditions générales d'utilisation du site et des services
          proposés.
        </h4>

        <p>
          L'utilisation du site{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          implique l'acceptation pleine et entière des conditions générales
          d'utilisation ci-après décrites. Ces conditions d'utilisation sont
          susceptibles d'être modifiées ou complétées à tout moment, les
          utilisateurs du site{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          sont donc invités à les consulter de manière régulière. Ce site est
          normalement accessible à tout moment aux utilisateurs. Une
          interruption pour raison de maintenance technique peut être toutefois
          décidée par{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          , qui s'efforcera alors de communiquer préalablement aux utilisateurs
          les dates et heures de l'intervention. Le site{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          est mis à jour régulièrement par <b> Florentin CRESPEL</b>. De la même
          façon, les mentions légales peuvent être modifiées à tout moment :
          elles s'imposent néanmoins à l'utilisateur qui est invité à s'y
          référer le plus souvent possible afin d'en prendre connaissance.
        </p>
        <br />

        <h4>3. Description des services fournis.</h4>

        <p>
          Le site{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          a pour objet de fournir une information concernant l'ensemble des
          activités de la société. <b> Florentin CRESPEL </b>
          s'efforce de fournir sur le site{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          des informations aussi précises que possible. Toutefois, il ne pourra
          être tenu responsable des omissions, des inexactitudes et des carences
          dans la mise à jour, qu'elles soient de son fait ou du fait des tiers
          partenaires qui lui fournissent ces informations. Tous les
          informations indiquées sur le site{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          sont données à titre indicatif, et sont susceptibles d'évoluer. Par
          ailleurs, les renseignements figurant sur le site{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications
          ayant été apportées depuis leur mise en ligne.
        </p>
        <br />

        <h4>4. Limitations contractuelles sur les données techniques.</h4>

        <p>
          Le site utilise la technologie REACT, framework de JavaScript. Le site
          Internet ne pourra être tenu responsable de dommages matériels liés à
          l'utilisation du site. De plus, l'utilisateur du site s'engage à
          accéder au site en utilisant un matériel récent, ne contenant pas de
          virus et avec un navigateur de dernière génération mis-à-jour.
        </p>
        <br />

        <h4>5. Propriété intellectuelle et contrefaçons.</h4>

        <p>
          <b>Florentin CRESPEL</b> est propriétaire des droits de propriété
          intellectuelle ou détient les droits d'usage sur tous les éléments
          accessibles sur le site, notamment les textes, certaines images,
          graphismes, logo, logiciels. Toute reproduction, représentation,
          modification, publication, adaptation de tout ou partie des éléments
          du site, quel que soit le moyen ou le procédé utilisé, est interdite,
          sauf autorisation écrite préalable de : <b>Florentin CRESPEL</b>.
          Toute exploitation non autorisée du site ou de l'un quelconque des
          éléments qu'il contient sera considérée comme constitutive d'une
          contrefaçon et poursuivie conformément aux dispositions des articles
          L.335-2 et suivants du Code de Propriété Intellectuelle.
        </p>
        <br />

        <h4>6. Limitations de responsabilité.</h4>
        <p>
          <b>Florentin CRESPEL</b> ne pourra être tenu responsable des dommages
          directs et indirects causés au matériel de l'utilisateur, lors de
          l'accès au site{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          , et résultant soit de l'utilisation d'un matériel ne répondant pas
          aux spécifications indiquées au point 4, soit de l'apparition d'un bug
          ou d'une incompatibilité.
        </p>
        <p>
          <b>Florentin CRESPEL</b> ne pourra également être tenu responsable des
          dommages indirects (par exemple qu'une perte de marché) consécutifs à
          l'utilisation du site{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          .
        </p>
        <p>
          Des espaces interactifs (possibilité de poser des questions dans
          l'espace contact) sont à la disposition des utilisateurs.{" "}
          <b>Florentin CRESPEL</b> se réserve le droit de supprimer, sans mise
          en demeure préalable, tout contenu déposé dans cet espace qui
          contreviendrait à la législation applicable en France, en particulier
          aux dispositions relatives à la protection des données. Le cas
          échéant, <b>Florentin CRESPEL</b> se réserve également la possibilité
          de mettre en cause la responsabilité civile et/ou pénale de
          l'utilisateur, notamment en cas de message à caractère raciste,
          injurieux, diffamant, ou pornographique, quel que soit le support
          utilisé (texte, photographie…).
        </p>
        <br />

        <h4>7. Gestion des données personnelles.</h4>

        <p>
          En France, les données personnelles sont notamment protégées par la
          loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004,
          l'article L. 226-13 du Code pénal et la Directive Européenne du 24
          octobre 1995.
        </p>
        <p>
          À l'occasion de l'utilisation du site{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          , peuvent êtres recueillies : l'URL des liens par l'intermédiaire
          desquels l'utilisateur a accédé au site{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          , le fournisseur d'accès de l'utilisateur, l'adresse de protocole
          Internet (IP) de l'utilisateur.
        </p>
        <p>
          En tout état de cause <b>Florentin CRESPEL</b> ne collecte des
          informations personnelles relatives à l'utilisateur que pour le besoin
          de certains services proposés par le site{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          . L'utilisateur fournit ces informations en toute connaissance de
          cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est
          alors précisé à l'utilisateur du site{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          l'obligation ou non de fournir ces informations.
        </p>
        <p>
          Conformément aux dispositions des articles 38 et suivants de la loi
          78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux
          libertés, tout utilisateur dispose d'un droit d'accès, de
          rectification et d'opposition aux données personnelles le concernant,
          en effectuant sa demande écrite et signée, accompagnée d'une copie du
          titre d'identité avec signature du titulaire de la pièce, en précisant
          l'adresse à laquelle la réponse doit être envoyée.
        </p>
        <p>
          Aucune information personnelle de l'utilisateur du site
          www.florentin-crespel.fr n'est publiée à l'insu de l'utilisateur,
          échangée, transférée, cédée ou vendue sur un support quelconque à des
          tiers. Seule l'hypothèse du rachat de <b>Florentin CRESPEL</b> et de
          ses droits permettrait la transmission des dites informations à
          l'éventuel acquéreur qui serait à son tour tenu de la même obligation
          de conservation et de modification des données vis à vis de
          l'utilisateur du site{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          .
        </p>
        <p>
          Le site n'est pas déclaré à la CNIL car il ne recueille pas
          d'informations personnelles.
        </p>
        <p>
          Les bases de données sont protégées par les dispositions de la loi du
          1er juillet 1998 transposant la directive 96/9 du 11 mars 1996
          relative à la protection juridique des bases de données.
        </p>
        <br />

        <h4>8. Liens hypertextes et cookies.</h4>

        <p>
          Le site{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          contient un certain nombre de liens hypertextes vers d'autres sites,
          mis en place avec l'autorisation de
          <b> Florentin CRESPEL</b>. Cependant, <b>Florentin CRESPEL</b> n'a pas
          la possibilité de vérifier le contenu des sites ainsi visités, et
          n'assumera en conséquence aucune responsabilité de ce fait.
        </p>
        <p>
          La navigation sur le site{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          est susceptible de provoquer l'installation de cookie(s) sur
          l'ordinateur de l'utilisateur. Un cookie est un fichier de petite
          taille, qui ne permet pas l'identification de l'utilisateur, mais qui
          enregistre des informations relatives à la navigation d'un ordinateur
          sur un site. Les données ainsi obtenues visent à faciliter la
          navigation ultérieure sur le site, et ont également vocation à
          permettre diverses mesures de fréquentation.
        </p>
        <p>
          Le refus d'installation d'un cookie peut entraîner l'impossibilité
          d'accéder à certains services. L'utilisateur peut toutefois configurer
          son ordinateur de la manière suivante, pour refuser l'installation des
          cookies : Sous Internet Explorer : onglet outil / options internet.
          Cliquez sur Confidentialité et choisissez Bloquer tous les cookies.
          Validez sur Ok. Sous Netscape : onglet édition / préférences. Cliquez
          sur Avancées et choisissez Désactiver les cookies. Validez sur Ok.
        </p>
        <p>Cookies d'analyse</p>
        <ul>
          <li>_ga, _gat : Cookies propres à Google Analytics (statistiques)</li>
        </ul>
        <br />

        <h4>9. Droit applicable et attribution de juridiction.</h4>

        <p>
          Tout litige en relation avec l'utilisation du site{" "}
          <Link to={url} target="_blank">
            <b>florentin-crespel.fr</b>
          </Link>{" "}
          est soumis aux tribunaux francais compétents statuant selon le droit
          français.
        </p>
        <br />

        <h4>10. Les principales lois concernées.</h4>

        <p>
          Loi n° 78-87 du 6 janvier 1978, notamment modifiée par la loi n°
          2004-801 du 6 août 2004 relative à l'informatique, aux fichiers et aux
          libertés.
        </p>
        <p>
          Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie
          numérique.
        </p>
        <br />

        <h4>11. Lexique.</h4>

        <p>
          Utilisateur : Internaute se connectant, utilisant le site susnommé.
        </p>
        <p>
          Informations personnelles : « les informations qui permettent, sous
          quelque forme que ce soit, directement ou non, l'identification des
          personnes physiques auxquelles elles s'appliquent » (article 4 de la
          loi n° 78-17 du 6 janvier 1978).
        </p>
      </div>
      <div>
        <button onClick={close}>FERMER</button>
      </div>
    </div>
  );
}
