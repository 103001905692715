import style from "./mockup.module.scss";
import mockup from "../../../../../assests/images/mockup/Elements de présentation.png";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Mockup() {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setPageWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div
      className={pageWidth <= 1023 ? style.containerMobile : style.container}
    >
      <LazyLoadImage
        src={mockup}
        alt="mockup"
        effect="blur"
        placeholderSrc="../../../../../assests/images/Logo n°2 (Fond-transparent).webp"
      />
    </div>
  );
}
