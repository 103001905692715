import { useEffect, useState } from "react";
import { scrollToSection } from "../../functions/scroll/Scrolls";
import style from "./Shortheader.module.scss";
import { Link, NavLink } from "react-router-dom";
import Navbar from "../header/components/mainbar/navbar/navbar";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { sectionIds } from "../header/components/mainbar/sectionIds";
import logo2 from "../../assests/images/Logo n°2 (Fond transparent).webp";

export default function Shortheader() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const [activedLink, setActivedLink] = useState("home");
  const [isScrolled, setIsScrolled] = useState(false);

  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  const determineActivedSection = () => {
    for (let i = sectionIds.length - 1; i >= 0; i--) {
      const section = document.getElementById(sectionIds[i]);
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 190 && rect.bottom >= 190) {
          setActivedLink(sectionIds[i]);
          break;
        }
      }
    }
  };

  scrollToSection();

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
      if (window.scrollY > 300) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
      determineActivedSection();
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled]);

  useEffect(() => {
    const handleResize = () => setPageWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <header
      className={`${style.mainbar}`}
    //   style={{
    //     position: scrollPosition > 50 && "fixed",
    //     top: scrollPosition > 50 && "0",
    //     backgroundColor: scrollPosition > 50 && "#000",
    //     boxShadow: scrollPosition > 50 && "0px 4px 3px #000",
    //   }}
    >
      <div className={`${style.container} d-flex aic jcsb`}>
        <Link
          to={"/"}
          className={`${style.logo} d-flex aic`}
          onClick={() => scrollToSection("HOME")}
          title="HOME"
        >
          <LazyLoadImage className={style.logo} src={logo2} alt="logo" />
        </Link>
        {pageWidth <= 1023 ? (
          <Navbar activedLink={activedLink} />
        ) : (
          <nav className="d-flex">
            <ul
              className={`
            d-flex aic`}
            >
              <li>
                <NavLink title="HOME" to={"/"}>
                  HOME
                </NavLink>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
}
