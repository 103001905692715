import style from "./info.module.scss";

export default function Infos() {
  return (
    <div className={`${style.contactContainer} d-flex f-column jcsb`}>
      <ul className={style.contactUl}>
        <li className={style.contactLi}>
          <i className="fa-solid fa-map-location-dot"></i>
          <span>
            <h4>ADRESSE</h4>
            <p>313, rue nationale 62290 Noeux-les-mines</p>
          </span>
        </li>
        <li className={style.contactLi}>
          <i className="fa-solid fa-phone"></i>
          <span>
            <h4>TELEPHONE</h4>
            <p>(+33) 6.19.47.55.86</p>
          </span>
        </li>
        <li className={style.contactLi}>
          <i className="fa-solid fa-envelope"></i>
          <span>
            <h4>E-MAIL</h4>
            <p>florentin.crespel@gmail.com</p>
          </span>
        </li>
        <li className={style.contactLi}>
          <i className="fa-solid fa-clock"></i>
          <span>
            <h4>HORAIRES</h4>
            <p>Lundi - Samedi</p>
            <p>8H - 19H</p>
          </span>
        </li>
        <li className={style.contactLi} id="FORM">
          <i className="fa-solid fa-book"></i>
          <span>
            <h4>S.I.R.E.N</h4>
            <p>984.979.179</p>
          </span>
        </li>
      </ul>
    </div>
  );
}
