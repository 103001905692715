import style from "./one.module.scss";

export default function One() {
  const userAgent = window.navigator.userAgent;

  const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
  return (
    <div className={style.banner}>
      <div className={isIOS ? style.ios : style.svg}></div>
      <div className={style.cover}></div>
    </div>
  );
}
