import { Link } from "react-router-dom";
import { scrollToSection } from "../../../../../functions/scroll/Scrolls";
import { sectionIds } from "../sectionIds";
import style from "./navbar.module.scss";
import { useState } from "react";

export default function Navbar({ activedLink }) {
  const [toggle, setToggle] = useState(false);

  const toggleBurger = (sectionId) => {
    setToggle(!toggle);
    for (let i = 0; i < sectionIds.length; i++) {
      if (sectionId === sectionIds[i]) {
        scrollToSection(sectionId);
      }
    }
  };

  return (
    <nav className={`${toggle ? style.open : style.nav}`}>
      {!toggle ? (
        <i className="fa-solid fa-bars" onClick={toggleBurger}></i>
      ) : (
        <>
          <i className="fa-solid fa-xmark" onClick={toggleBurger}></i>
          <ul className={`${style.ulOpen}`}>
            {sectionIds.map((sectionId, i) => (
              <li key={i} onClick={() => toggleBurger(sectionId)}>
                <Link
                  to={"/"}
                  className={activedLink === sectionId ? style.active : ""}
                >
                  {sectionId}
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </nav>
  );
}
