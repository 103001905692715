import style from "./loading.module.scss";
import logo from "../../../../assests/images/Logo n°1 (Fond-transparent).webp";
import { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Loading() {
  const imageRef = useRef(null);
  const h1Ref = useRef(null);
  const loadingRef = useRef(null);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setPageWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fade = setTimeout(() => {
      imageRef.current.classList.remove(`${style.fadein}`);
      h1Ref.current.classList.remove(`${style.fadein}`);
    }, 500);
    const loading = setTimeout(() => {
      loadingRef.current.classList.add(style.loadingout);
    }, 1500);

    return () => clearTimeout(fade, loading);
  });

  return (
    <div ref={loadingRef} className={`${style.loading} d-flex f-column jcc`}>
      <div
        ref={imageRef}
        className={`${style.spin} ${style.fadein} ${
          pageWidth >= 1024 ? style.img : style.imgMobile
        }`}
      >
        <LazyLoadImage src={logo} alt="logo loading" />
      </div>
      <h1
        ref={h1Ref}
        className={`${style.spin} ${style.fadein} ${
          pageWidth >= 1024 ? style.h1 : style.h1Mobile
        }`}
      >
        DEVELOPPEUR WEB INDEPENDANT
      </h1>
    </div>
  );
}
