import style from "./popup.module.scss";

export default function Popup({ errors, width }) {
  const errorMessage = Object.keys(errors).map((a, i) => {
    const error = errors[a];
    return (
      <li key={i} className={style.lierror}>
        {error.message}
      </li>
    );
  });

  return (
    <ul className={width >= 1024 ? style.ulerror : style.ulerrorMobile}>
      {errorMessage}
    </ul>
  );
}
