import { Suspense, useEffect, useState } from "react";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Loading from "./pages/homepage/components/loading/loading";
import { useCookies } from "react-cookie";
import CookieConsent from "./cookies/CookieContent";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Outlet } from "react-router-dom";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [cookies] = useCookies(["cookieConsent"]);

  useEffect(() => {
    const loading = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(loading);
  }, []);

  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey="6LcNq5wpAAAAANXENptrcZsADMZHuAfd5POhZfH9"
        scriptProps={{
          async: false,
          defer: false,
          appendTo: "head",
          nonce: undefined,
        }}
      >
        <Loading />
        {/* <Header isLoading={isLoading} /> */}
        <Suspense>
          <>
            <Outlet />
          </>
          {!cookies.cookieConsent && <CookieConsent />}
        </Suspense>
        <Footer />
      </GoogleReCaptchaProvider>
    </>
  );
}

export default App;
