import { useEffect, useState } from "react";
import style from "./technologies.module.scss";

export default function Technologies() {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setPageWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <section id="TECHNOLOGIES" className={`${style.section}`}>
      <div className={`${style.sec} d-flex f-column jcc aic`}>
        <div className={pageWidth >= 1024 ? style.title : style.titleMobile}>
          <h2>LES TECHNOLOGIES</h2>
          <h3>à votre service</h3>
        </div>
        <div
          className={`${
            pageWidth >= 1024 ? style.container : style.containerMobile
          } d-flex jcsb`}
        >
          <div className={`${style.left} d-flex f-column`}>
            <article>
              <div className={style.prog}>
                <span className={style.percent} style={{ width: "95%" }}>
                  {pageWidth >= 1024 ? (
                    <>
                      <h4>HTML</h4>
                      <i className="fa-brands fa-html5"></i>
                    </>
                  ) : (
                    <>
                      <i className="fa-brands fa-html5"></i>
                      <h4>HTML</h4>
                    </>
                  )}
                </span>
              </div>
            </article>
            <article>
              <div className={style.prog}>
                <span className={style.percent} style={{ width: "90%" }}>
                  {pageWidth >= 1024 ? (
                    <>
                      <h4>CSS</h4>
                      <i className="fa-brands fa-css3-alt"></i>
                    </>
                  ) : (
                    <>
                      <i className="fa-brands fa-css3-alt"></i>
                      <h4>CSS</h4>
                    </>
                  )}
                </span>
              </div>
            </article>
            <article>
              <div className={style.prog}>
                <span className={style.percent} style={{ width: "80%" }}>
                  {pageWidth >= 1024 ? (
                    <>
                      <h4>PHP</h4>
                      <i className="fa-brands fa-php"></i>
                    </>
                  ) : (
                    <>
                      <i className="fa-brands fa-php"></i>
                      <h4>PHP</h4>
                    </>
                  )}
                </span>
              </div>
            </article>
            <article>
              <div className={style.prog}>
                <span className={style.percent} style={{ width: "70%" }}>
                  {pageWidth >= 1024 ? (
                    <>
                      <h4>Javascript</h4>
                      <i className="fa-brands fa-js"></i>
                    </>
                  ) : (
                    <>
                      <i className="fa-brands fa-js"></i>
                      <h4>Javascript</h4>
                    </>
                  )}
                </span>
              </div>
            </article>
            <article>
              <div className={style.prog}>
                <span className={style.percent} style={{ width: "65%" }}>
                  {pageWidth >= 1024 ? (
                    <>
                      <h4>node JS</h4>
                      <i className="fa-brands fa-node"></i>
                    </>
                  ) : (
                    <>
                      <i className="fa-brands fa-node"></i>
                      <h4>node JS</h4>
                    </>
                  )}
                </span>
              </div>
            </article>
          </div>
          <div className={`${style.right} d-flex f-column`}>
            <article>
              <div className={style.prog}>
                <span className={style.percent} style={{ width: "85%" }}>
                  <i className="fa-brands fa-react"></i>
                  <h4>REACT - React Native</h4>
                </span>
              </div>
            </article>
            <article>
              <div className={style.prog}>
                <span className={style.percent} style={{ width: "40%" }}>
                  <i className="fa-brands fa-symfony"></i>
                  <h4>Symfony</h4>
                </span>
              </div>
            </article>
            <article>
              <div className={style.prog}>
                <span className={style.percent} style={{ width: "85%" }}>
                  <i className="fa-solid fa-database"></i>
                  <h4>MySQL - SQL</h4>
                </span>
              </div>
            </article>
            <article>
              <div className={style.prog}>
                <span className={style.percent} style={{ width: "50%" }}>
                  <i className="fa-solid fa-database"></i>
                  <h4>NO SQL - Firebase</h4>
                </span>
              </div>
            </article>
            <article>
              <div className={style.prog}>
                <span className={style.percent} style={{ width: "45%" }}>
                  <i className="fa-solid fa-pen"></i>
                  <h4>Ps - Ai</h4>
                </span>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
