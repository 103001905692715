import style from "./background.module.scss";
import img2 from "../../../../assests/images/background/pexels-photo-4050451.webp";
import img3 from "../../../../assests/images/background/pexels-photo-6612767.webp";
import img1 from "../../../../assests/images/background/20240220_164235.webp";
import { Carousel } from "react-responsive-carousel";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Background({ isLoading }) {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setPageWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={style.img}>
      <Carousel
        className={`${style.carousel}`}
        autoPlay={!isLoading && true}
        swipeable={false}
        swipe={false}
        infiniteLoop
        interval={6500}
        preventMovementUntilSwipeScrollTolerance={true}
        showIndicators={false}
        showThumbs={false}
      >
        <div>
          <LazyLoadImage
            className={style.background}
            src={img1}
            alt="background1"
          />
          <div className={style.cover}>
            <div className={`${style.text} d-flex f-column`}>
              <h2
                className={`${pageWidth >= 1024 ? style.h2 : style.h2Mobile}`}
              >
                CONCEPTEUR D'APPLICATION INDEPENDANT
              </h2>
              <h3
                className={`${pageWidth >= 1024 ? style.h3 : style.h3Mobile}`}
              >
                Florentin CRESPEL
              </h3>
            </div>
          </div>
        </div>
        <div>
          <LazyLoadImage
            className={style.background}
            src={img2}
            alt="background2"
          />
          <div className={style.cover}>
            <div className={`${style.text} d-flex f-column`}>
              <h2
                className={`${pageWidth >= 1024 ? style.h2 : style.h2Mobile}`}
              >
                CREATION DE SITES WEB
              </h2>
              <h3
                className={`${pageWidth >= 1024 ? style.h3 : style.h3Mobile}`}
              >
                site vitrine, landing page, évènementiel, e-commerce, intranet,
                application
              </h3>
            </div>
          </div>
        </div>
        <div>
          <LazyLoadImage
            className={style.background}
            src={img3}
            alt="background3"
          />
          <div className={style.cover}>
            <div className={`${style.text} d-flex f-column`}>
              <h2
                className={`${pageWidth >= 1024 ? style.h2 : style.h2Mobile}`}
              >
                WEB DESIGN & CONCEPTION GRAPHIQUE
              </h2>
              <h3
                className={`${pageWidth >= 1024 ? style.h3 : style.h3Mobile}`}
              >
                logo, templates web, maquettes, prototypages
              </h3>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
}
