const API = "https://getform.io/f/DdwOpzd1";

export async function Contact(data) {
  await fetch(API, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}
