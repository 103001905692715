import style from "./NotFound.module.scss";
import { NavLink } from "react-router-dom";

export default function NotFound() {
  return (
    <div className={style.NotFound}>
      <h1>404 page non trouvée</h1>
      <p>La page que vous avez demandée est introuvable.</p>
      <NavLink to={"/"}>Retour a la page d'accueil</NavLink>
    </div>
  );
}
