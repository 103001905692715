import { Link } from "react-router-dom";
import style from "./social.module.scss";

export default function Social() {
  return (
    <div className={`${style.social}`}>
      <div className={`${style.container} d-flex aic jcsb`}>
        <ul className={`${style.rslist} d-flex aic`}>
          <li>
            <Link
              target="_blank"
              to={"https://www.facebook.com/profile.php?id=61557184163105"}
              title="facebook"
            >
              <i className="fa-brands fa-facebook"></i>
            </Link>
          </li>
          <li>
            <Link
              target="_blank"
              to={"https://www.linkedin.com/in/florentin-crespel-37a59a188/"}
              title="linkedin"
            >
              <i className="fa-brands fa-linkedin"></i>
            </Link>
          </li>
          <li>
            <Link
              target="_blank"
              to={"https://www.instagram.com/florentin.crespel/"}
              title="instagram"
            >
              <i className="fa-brands fa-instagram"></i>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
