import "./header.module.scss";
import Background from "./components/background/background";
import Mainbar from "./components/mainbar/mainbar";
import Topbar from "./components/topbar/topbar";
import ScrollBottom from "./components/scroll/scrollBottom";

export default function Header({ isLoading }) {
  return (
    <header id="HOME">
      <Background isLoading={isLoading} />
      <Topbar />
      <Mainbar />
      <ScrollBottom />
    </header>
  );
}
