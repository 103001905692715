import { useEffect, useState } from "react";
import Devis from "../../components/devis/devis";
import style from "./Services.module.scss";
import map from "../../assests/images/background/map.webp";
import Shortheader from "../../components/shortheader/Shortheader";

export default function Services() {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setPageWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <header>
        <Shortheader />
      </header>
      <main>
        <section id="SERVICES" className={`${style.section}`}>
          <div className="section d-flex f-column jcc aic">
            <div
              className={pageWidth >= 1024 ? style.title : style.titleMobile}
            >
              <h2>SERVICES</h2>
              <h3>Des prestations qui s'adaptent à vos besoins</h3>
            </div>
            <div
              style={{
                backgroundImage: `url(${map})`,
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
              }}
              className={`${
                pageWidth >= 1024 ? style.container : style.containerMobile
              } d-flex jcsb`}
            >
              <article
                className={`${
                  pageWidth >= 1024 ? style.service : style.serviceMobile
                } d-flex f-column aic`}
              >
                <span className="d-flex aic jcc">
                  <i className="fa-regular fa-lightbulb"></i>
                </span>
                <div>
                  <h4>Gestion de projet web</h4>
                  <p>
                    Site vitrine, landing page, évènementiel, e-commerce,
                    intranet, application.
                  </p>
                </div>
              </article>
              <article
                className={`${
                  pageWidth >= 1024 ? style.service : style.serviceMobile
                } d-flex f-column aic`}
              >
                <span>
                  <i className="fa-solid fa-pencil"></i>
                </span>
                <div>
                  <h4>Conception graphique & webdesign</h4>
                  <p>
                    Logo, cartes de visite, exemple de site web, maquettes,
                    prototypes...
                  </p>
                </div>
              </article>
              <article
                className={`${
                  pageWidth >= 1024 ? style.service : style.serviceMobile
                } d-flex f-column aic`}
              >
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
                <div>
                  <h4>Référencement naturel</h4>
                  <p>
                    Affichage sémantique des informations, des pages structurées
                    pour un référencement optimal.
                  </p>
                </div>
              </article>
              <article
                className={`${
                  pageWidth >= 1024 ? style.service : style.serviceMobile
                } d-flex f-column aic`}
              >
                <span>
                  <i className="fa-solid fa-leaf"></i>
                </span>
                <div>
                  <h4>Projets écoresponsables</h4>
                  <p>
                    Une structures et des chargement soucieux de
                    l'environnement.
                  </p>
                </div>
              </article>
              <article
                className={`${
                  pageWidth >= 1024 ? style.service : style.serviceMobile
                } d-flex f-column aic`}
              >
                <span>
                  <i className="fa-solid fa-rotate"></i>
                </span>
                <div>
                  <h4>Dynamisme des pages</h4>
                  <p>
                    Des animations de contenu non intrusives pour embellir votre
                    projet.
                  </p>
                </div>
              </article>
              <article
                className={`${
                  pageWidth >= 1024 ? style.service : style.serviceMobile
                } d-flex f-column aic`}
              >
                <span>
                  <i className="fa-solid fa-arrows-left-right-to-line"></i>
                </span>
                <div>
                  <h4>Responsive design</h4>
                  <p>
                    Compatible sur tous les supports, tablette & smartphone.
                  </p>
                </div>
              </article>
              <article
                className={`${
                  pageWidth >= 1024 ? style.service : style.serviceMobile
                } d-flex f-column aic`}
              >
                <span>
                  <i className="fa-solid fa-screwdriver-wrench"></i>
                </span>
                <div>
                  <h4>Développement sur-mesure</h4>
                  <p>
                    Outils adaptés à votre métier, applications & solutions
                    personnalisables.
                  </p>
                </div>
              </article>
              <article
                className={`${
                  pageWidth >= 1024 ? style.service : style.serviceMobile
                } d-flex f-column aic`}
              >
                <span>
                  <i className="fa-solid fa-gears"></i>
                </span>
                <div>
                  <h4>Espace administrateur</h4>
                  <p>Plateforme pour piloter le contenu de votre site.</p>
                </div>
              </article>
            </div>
            <Devis />
          </div>
        </section>
      </main>
    </>
  );
}
