// import Four from "../../components/banners/four/four";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import One from "../../components/banners/one/one";
import Three from "../../components/banners/three/three";
import Two from "../../components/banners/two/two";
import Header from "../../components/header/header";
import About from "./components/about/about";
import Form from "./components/contact/components/form/form";
// import Projets from "./components/projets/projets";
import Services from "./components/services/services";
import Technologies from "./components/technologies/technologies";
import ScrollSpy from "react-ui-scrollspy";

export default function Homepage() {
  return (
    <>
      <Header />
      <main>
        <ScrollSpy>
          <Services />
          <One />
          <Technologies />
          <Two />
          {/* <Projets /> */}
          <About />
          <Three />
          {/* <Four /> */}
          <GoogleReCaptchaProvider
            reCaptchaKey="6LcNq5wpAAAAANXENptrcZsADMZHuAfd5POhZfH9"
            scriptProps={{
              async: false,
              defer: false,
              appendTo: "head",
              nonce: undefined,
            }}
          >
            <Form />
          </GoogleReCaptchaProvider>
        </ScrollSpy>
      </main>
    </>
  );
}
