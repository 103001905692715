import { Link, NavLink } from "react-router-dom";
import style from "./vcard.module.scss";
import logo from "../../assests/images/Logo n°1 (Fond-transparent).webp";
import vcard from "../../assests/VCard/vcard.vcf";

export default function Vcard() {
  return (
    <main className={`${style.main} d-flex f-column aic jcc`}>
      <img src={logo} alt="logo" />
      <h1>VCARD</h1>
      <h2>Florentin Crespel</h2>
      <section className={`d-flex f-column`}>
        <a href={vcard} download={vcard}>
          <button className={style.primary}>TELECHARGER LE CONTACT</button>
        </a>
        <NavLink to={"/"} className={style.secondary}>
          <button>SITE WEB</button>
        </NavLink>
      </section>
      <section>
        <nav className={`d-flex jcsb flex-fill`}>
          <NavLink
            to={"https://www.facebook.com/profile.php?id=61557184163105"}
            target="_blank"
            title="facebook"
          >
            <li>
              <i className="fa-brands fa-facebook"></i>
            </li>
          </NavLink>
          <NavLink
            to={"https://www.linkedin.com/in/florentin-crespel-37a59a188/"}
            target="_blank"
            title="linkedin"
          >
            <li>
              <i className="fa-brands fa-linkedin"></i>
            </li>
          </NavLink>
          <NavLink
            to={"https://www.instagram.com/florentin.crespel/"}
            target="_blank"
            title="instagram"
          >
            <li>
              <i className="fa-brands fa-instagram"></i>
            </li>
          </NavLink>
        </nav>
      </section>
    </main>
  );
}
