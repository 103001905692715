import style from "./form.module.scss";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Popup from "./popup";
import Infos from "../infos/infos";
import { useCallback, useEffect, useState } from "react";
import { Contact } from "../../../../../../apis/contact";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { cptchKey } from "../../../../../../config/captcha";

export default function Form() {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [emailSent, setEmailSent] = useState(false);
  const [token, setToken] = useState("");

  const onReCAPTCHACallback = useCallback((t) => {
    setToken(t);
  }, []);

  useEffect(() => {
    const handleResize = () => setPageWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const validationSchema = yup.object({
    name: yup
      .string()
      .required("Le champs Nom / Prenom doit être saisi")
      .min(2, "Entrez au moins 2 caractères"),
    mail: yup
      .string()
      .required("Le champs E-mail doit être saisi")
      .email("Email non valide"),
    phone: yup.string(),
    descr: yup
      .string()
      .required("Le champs de description de projet doit être saisi")
      .min(10, "Entrez au moins 10 caractères"),
    check: yup
      .boolean()
      .oneOf(
        [true],
        "Vous devez valider les conditions d'utilisation pour envoyer le formulaire"
      ),
  });

  const initialVlaues = {
    name: "",
    email: "",
    phone: "",
    descr: "",
    check: false,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    clearErrors,
    reset,
  } = useForm({
    initialVlaues,
    resolver: yupResolver(validationSchema),
  });

  const submit = handleSubmit(async (value) => {
    const data = {
      name: value.name,
      mail: value.mail,
      phone: value.phone,
      descr: value.descr,
      token: token,
    };
    clearErrors();
    try {
      Contact(data);
      reset();
      setEmailSent(true);
      window.alert("Formulaire de contact envoyer avec succes !");
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <section id="CONTACT" className={`${style.section}`}>
      <div className="section d-flex f-column jcc aic">
        <div
          className={`${
            pageWidth >= 1024 ? style.title : style.titleMobile
          } d-flex f-column aic`}
        >
          <h2>CONTACTEZ MOI</h2>
          <h3>Obtenez un devis gratuit</h3>
        </div>
        <div
          className={`${
            pageWidth >= 1024 ? style.container : style.containerMobile
          } d-flex jcsb`}
        >
          <div className={`${style.column} d-flex f-column`}>
            <Infos />
            {errors && pageWidth >= 1024 && (
              <Popup errors={errors} width={pageWidth} />
            )}
          </div>
          <GoogleReCaptchaProvider reCaptchaKey={cptchKey}>
            <form
              className={"d-flex f-column"}
              onSubmit={submit}
              disabled={emailSent}
              id="FORM"
            >
              <div className={`${style.input}`}>
                <span className={style.icone}>
                  <p>
                    <i className="fa-solid fa-user"></i>
                  </p>
                </span>
                <input
                  className={errors?.name ? style.error : ""}
                  type="text"
                  name="name"
                  placeholder={
                    errors?.name ? errors.name.message : "Nom/Prénom"
                  }
                  {...register("name")}
                  disabled={emailSent && true}
                />
                <span className={style.obligatoire}>
                  <p>*</p>
                </span>
              </div>
              <div className={`${style.input}`}>
                <span className={style.icone}>
                  <p>
                    <i className="fa-solid fa-at"></i>
                  </p>
                </span>
                <input
                  className={errors?.mail ? style.error : ""}
                  type="text"
                  name="mail"
                  placeholder={errors?.mail ? errors.mail.message : "E-mail"}
                  {...register("mail")}
                  disabled={emailSent && true}
                />
                <span className={style.obligatoire}>
                  <p>*</p>
                </span>
              </div>
              <div className={`${style.input}`}>
                <span className={style.icone}>
                  <p>
                    <i className="fa-solid fa-phone"></i>
                  </p>
                </span>
                <input
                  className={errors?.phone ? style.error : ""}
                  type="phone"
                  name="phone"
                  placeholder={
                    errors?.phone ? errors.phone.message : "Téléphone"
                  }
                  {...register("phone")}
                  disabled={emailSent && true}
                />
              </div>
              <div className={`${style.input}`}>
                <textarea
                  className={errors?.descr ? style.error : ""}
                  name="descr"
                  placeholder={
                    errors?.descr
                      ? errors.descr.message
                      : "Merci de détailler le plus possible votre besoin afin de faciliter nos futurs échanges."
                  }
                  rows="10"
                  {...register("descr")}
                  disabled={emailSent && true}
                ></textarea>
                <span className={style.obligatoire}>
                  <p>*</p>
                </span>
              </div>
              <span className={`${style.check} d-flex aic`}>
                <input
                  className={errors?.check ? style.error : ""}
                  type="checkbox"
                  name="check"
                  id="check"
                  {...register("check")}
                  disabled={emailSent && true}
                />
                <label
                  htmlFor="check"
                  className={errors?.check ? style.error : style.conditions}
                >
                  En soumettant ce formulaire, j'accepte que les informations
                  saisies soient exploitées dans le cadre de la demande de
                  contact et de la relation commerciale qui peut en découler.
                </label>
              </span>
              <GoogleReCaptcha onVerify={onReCAPTCHACallback} />
              <button disabled={isSubmitting || emailSent === true}>
                ENVOYER
              </button>
            </form>
          </GoogleReCaptchaProvider>
          {errors && pageWidth <= 1023 && (
            <Popup errors={errors} width={pageWidth} />
          )}
        </div>
      </div>
    </section>
  );
}
