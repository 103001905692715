import style from "./top.module.scss";
import { scrollToSection } from "../../functions/scroll/Scrolls";
import { useEffect, useState } from "react";

export default function Top() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  scrollToSection();

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
      if (window.scrollY > 300) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled]);

  return (
    scrollPosition > 0 && (
      <div className={style.top} onClick={() => scrollToSection("HOME")}>
        <button>
          <i className="fa-solid fa-arrow-up"></i>
        </button>
      </div>
    )
  );
}
