import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import NotFound from "../pages/homepage/components/notFound/Notfound";
import Homepage from "../pages/homepage/homepage";
import Vcard from "../pages/vcard/vcard";
import Services from "../pages/services/Services";
import Contact from "../pages/contact/Contact";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Homepage />,
      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
    ],
  },
  {
    path: "/vcard",
    element: <Vcard />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
