import { useEffect, useState } from "react";
import style from "./topbar.module.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Social from "../../../social/social";

export default function Topbar() {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setPageWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function handleCopy() {
    window.alert("Adresse mail copiée dans la presse à papier");
  }

  return (
    <div className={`${style.topbar}`}>
      <div
        className={`${
          pageWidth >= 1024 ? style.container : style.containerMobile
        } d-flex aic jcsb`}
      >
        <Social />
        <ul className={`${style.contactlist} d-flex aic`}>
          <li>
            <p>
              <i className="fa-solid fa-phone"></i>
              (+33) 6.19.47.55.86
            </p>
          </li>
          <CopyToClipboard
            className={style.copy}
            onCopy={handleCopy}
            text="florentin-crespel@gmail.com"
          >
            <li>
              <p>
                <i className="fa-solid fa-envelope"></i>
                florentin-crespel@gmail.com
              </p>
            </li>
          </CopyToClipboard>
        </ul>
      </div>
    </div>
  );
}
