import { scrollToSection } from "../../../../functions/scroll/Scrolls";
import style from "./scrollBottom.module.scss";

export default function ScrollBottom() {
  scrollToSection();

  return (
    <button
      onClick={() => scrollToSection("SERVICES")}
      className={`${style.scroll} d-flex f-column aic jcsb`}
    >
      <p>scroll</p>
      <i className="fa-solid fa-arrow-down"></i>
    </button>
  );
}
