import { useEffect, useState } from "react";
import style from "./devis.module.scss";
import { scrollToSection } from "../../functions/scroll/Scrolls";
import { Navigate, useNavigate } from "react-router-dom";

export default function Devis() {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const location = window.location.pathname;
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setPageWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  scrollToSection();

  return (
    <button
      className={pageWidth >= 1024 ? style.devis : style.devisMobile}
      onClick={() =>
        location === "/services"
          ? navigate("/contact")
          : scrollToSection(`${pageWidth >= 1024 ? "CONTACT" : "FORM"}`)
      }
    >
      Besoin d'une estimation ? N'attendez plus et faites votre demande de devis
      GRATUIT !
    </button>
  );
}
