import { useEffect, useState } from "react";
import Contain from "./components/contain/contain";
import Mentions from "./components/mentions/mentions";
import Social from "../social/social";
import Top from "../top/Top";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from "../../assests/images/Logo n°2 (Fond transparent).webp";

export default function Footer() {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setPageWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <footer>
      <Top />
      <div className={"top"}>
        <LazyLoadImage src={logo} alt="logo florentin crespel" />
        <Social />
      </div>
      <Contain width={pageWidth} />
      <Mentions width={pageWidth} />
    </footer>
  );
}
