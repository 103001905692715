import { useState } from "react";
import style from "./mentions.module.scss";
import Popup from "../../../popup/popup";
import { Link } from "react-router-dom";

export default function Mentions({ width }) {
  const [legales, setLegales] = useState(null);

  function handleLegales() {
    setLegales(!legales);
  }

  return (
    <div className={`${style.mentions}`}>
      {legales && <Popup close={handleLegales} />}
      <div
        className={`${style.container} d-flex ${
          width <= 1023 && "f-column"
        } jcsb`}
      >
        <h5 className={style.mentions} onClick={handleLegales}>
          MENTIONS LEGALES
        </h5>
        <h5>
          Certaines images proviennent de{" "}
          <Link target="_blank" to={"https://www.pexels.com/fr-fr/"}>
            www.pexels.com
          </Link>
        </h5>
        <h5>
          Les icones proviennent de{" "}
          <Link target="_blank" to={"https://fontawesome.com/icons"}>
            www.fontawesome.com
          </Link>
        </h5>
        <h5>
          Conçu et développé par{" "}
          <Link to={"https://florentin-crespel.fr"}>Florentin CRESPEL</Link> -
          COPYRIGHT © 2024
        </h5>
      </div>
    </div>
  );
}
