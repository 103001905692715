import style from "./contain.module.scss";
import { sectionIds } from "../../../header/components/mainbar/sectionIds";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Codeur } from "./Codeur";
import { scrollToSection } from "../../../../functions/scroll/Scrolls";

export default function Contain({ width }) {
  const [scrollPosition, setScrollPosition] = useState(0);

  const [activedLink, setActivedLink] = useState("home");
  const [isScrolled, setIsScrolled] = useState(false);

  scrollToSection();

  const determineActivedSection = () => {
    for (let i = sectionIds.length - 1; i >= 0; i--) {
      const section = document.getElementById(sectionIds[i]);
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 190 && rect.bottom >= 190) {
          setActivedLink(sectionIds[i]);
          break;
        }
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
      if (window.scrollY > 300) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
      determineActivedSection();
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition, activedLink, isScrolled]);

  return (
    <div className={style.contain}>
      <div
        className={`${
          width >= 1024 ? style.container : style.containerMobile
        } d-flex jcsb`}
      >
        <div className={`${style.one}`}>
          <h4>Concepteur développeur indépendant</h4>
          <p>
            Développeur web front-end & back-end & Webdesigner freelance, je
            suis à votre disposition pour répondre à tous vos projets de
            création de sites internet, de développement spécifique ou
            d'applications web.
          </p>
          <p>
            Passionné par les technologies liées au Web, je mets mes compétences
            au service de vos besoins dans divers domaines.
          </p>
        </div>
        <div className={`${style.two}`}>
          <h4>Florentin CRESPEL</h4>
          <ul>
            {sectionIds.map((sectionId, i) => (
              <li key={i} onClick={() => scrollToSection(sectionId)}>
                <Link to={"/"}>{sectionId}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className={`${style.two}`}>
          <h4>Autres liens professionels</h4>
          <ul>
            {Codeur.map((c, i) => (
              <li key={i}>
                <Link target="_blank" to={`${c.url}`}>
                  {c.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
