export const Codeur = [
  { name: "Malt", url: "https://www.malt.fr/profile/florentincrespel2" },
  { name: "Codeur.com", url: "https://www.codeur.com/-crespeldev" },
  // { name: "Fieverr", url: "https://fr.fiverr.com/crespel" },
  { name: "GitHub", url: "https://github.com/Funkinsz" },
  {
    name: "Freelance-informatique",
    url: "https://www.freelance-informatique.fr/cv-developpeur-react-js-full-stack-240410C002",
  },
];
